.page-not-found{
    background-image: url('../../assets/pagina-404.png');
    width: 100vw;
    height: 100vh;
    background-position: center;
    background-size: contain;
    text-align: center;
    background-repeat: no-repeat;
}

.page-not-found-message{
    background-color: var(--primary-color);    
    color: var(--text-color-white);
    padding: 16px;
}
.page-not-found-message h1{
    font-size: 40px;
    font-weight: bold;
}

.page-not-found-back{
    display: flex;
    justify-content: center;
    align-items: center;

}
.page-not-found-back-text{
    font-size: 24px;
 
    &:hover{
        cursor: pointer;
        text-decoration: underline;
        color: var(--secondary-color);
    }
}
.page-not-found-back-text a{

    color: inherit;
}
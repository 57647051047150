.header{
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
    height: 80px;
    z-index: 999;
    position: fixed;
    font-weight: bold;
    min-width: 286px;
    background-color: var(--primary-color);
    transition: background-color 0.5s ease, box-shadow 0.5s ease;

}
.header--transparent {
    background-color: transparent;
    box-shadow: none;    
}

.header__logo{
    position: absolute;
    left: 8px;
    top: auto;
    width: 250px;
    height: 250px;
    z-index: 999;
}

.header_divider_drawer{
    display: flex;
    justify-content: space-around;
    flex-wrap: wrap;
    height:90%;
    color: #fff;
}

.header_divider:hover, .header_divider_drawer:hover{
cursor: pointer;
}

.header_divider{
    max-width: 800px;
    gap: 100px;
    display: flex;
    top: auto;
    background-color: transparent;
    position: absolute;
    right: 170px;
    
     
}

.header_divider a{
    letter-spacing: 2px;
}
.header_divider a{
    text-decoration: none;
    color: var(--text-color-white);
}


.drawer_divider__item{
    color: var(--text-color-white);
}

 
.button_section{
    display: none;  
}
@media (max-width: 1288px) {
    .header_divider{
        right: 170px;
    }
}
@media (max-width: 1170px) {
    .header_divider{
        right: 16px;
        gap: 32px;
    }
}


@media (max-width: 800px) {

    .button_section{
        display: flex;  
    }

    .button_menu{
        position: absolute;
        right: 8px;
        top: 24px;
        color:  var(--text-color-white);
    }
 
    
    .header_divider{
        display: none;

    }

    .header_divider_drawer{
        display: flex;
        flex-wrap: wrap;
        gap: 16px; 
        justify-content: space-around;
        align-items: center;
    }

    .button_menu{
        font-size: 32px;
    }
    
    .drawer_divider__item{
        width: 100%;
        text-align: center;
        text-decoration: none;       
        color: var(--text-color-white);
        font-size: 18px;
    }

}
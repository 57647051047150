.footer{
    background-color: var(--background-container);
    width: 100%;
    flex-wrap: wrap;
    gap: 32px;
    padding: 8px;
  
}

.footer__zennon{
    text-align: justify;
    color: #fff;
    padding:  16px;
    font-size: 10px;
    max-width: calc(1024px * 2);
    margin: 0 auto;
} 

.footer_aditional_infos{
    display: flex;
    justify-content: space-around;
    align-items: center;
    max-width: 1024px;
    margin: 0 auto;
    color: #fff;
    font-size: 12px;
    flex-wrap: wrap;
}

.footer__logo {
    width: 200px; 
    height: 200px;
    background-image: url('../../assets/logo.png');
    background-size: contain; 
    background-repeat: no-repeat; 
    background-position: center; 
}

.footer__develped_by{
    text-decoration: none;
    color: #FFF;

    &:hover{
        text-decoration: underline;
        cursor: pointer;
        color: var(--secondary-color);
    }
}

.footer_cookies{
    color: inherit;
}

@media (max-width: 742px) {
    .footer{
        display: flex;
        flex-wrap: wrap;
         

    }
    .footer__zennon{
        padding: 16px;
        margin-top: -20px;
        
        
    }
    .footer__logo{
        width: 200px; 
        height: 160px;
    }

    .footer__logo,
    .footer__copy{
        width: 90%;
        margin: 16px auto;
        display: flex;
        justify-content: center;
        align-items: center;
        gap: 16px;

    }

    .footer__develped_by{
        color: #FFF;
    }


    .footer__logo{
        margin-bottom: -32px;
    }
}
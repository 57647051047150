.back_to_home{
    display: flex;
    margin-left: 16px;
    padding-top: 16px;
    color: unset;
}

.back_to_home a{
    color: unset;
    text-decoration: none;
    margin-left: 4px;
    &:hover{
        text-decoration: underline;
    }
}
.back_to_home span{
    font-size: 24px;

}

.cookie-policy-page{
    background-color: var(--primary-color);
    color: var(--text-color-white);
    height: 100%;
    width: 100%;

}

#cookie-policy{
    text-align: center;
    max-width: 1024px;
    margin: auto auto;
    letter-spacing: normal;
    line-height: 1.7;
    padding-left: 16px;
}
.cookie-policy-tittle{
    font-size: 24px;
    text-align: left;
    font-weight: bold;
}
.top{
    padding-top: 24px !important; 
}

.section_policy{
    text-align: left;
}
.section_title{
    font-size: 40px;
    margin-bottom: 16px;
    text-align: center;
    font-weight: bold;
}
.section_description{
    font-size: 16px;
    row-gap: 20px;
    padding: 16px;

}
.section_sub_title{
    font-size: 24px;
    font-weight: bold;
}

.definition_item{
    text-decoration: dashed;
}

.list_definitions{
    list-style-type: circle;
    padding-left: 24px;
}


.list_definitions li strong{
    font-weight: bold;

}
@media (max-width:768px) {
    #cookie-policy > * {
        text-align: left !important; 
        padding: 16px;
    }
}
#team{
    margin: 40px 0;
}
.team_section_tittle{
    font-size: 24px;
    font-weight: bold;
    text-align: center;
    width: 100%;
    color: var(--primary-color);
    margin: 40px 0;
}

.team_tittle{
    width: 100%;
    text-align: center;
    font-size: 24px;
    margin: 40px 0;
}

.team_person{
    width: 300px;
    min-height: 100px;
    border: 1px solid var(--background-gray);
    text-align: center;
    border-radius: 10px;
    border-bottom: 1px solid var(--background-gray);
}

.team_person h2{    
    border-bottom: 1px solid var(--background-gray);
}

.team_person_tittle{
    border-top-right-radius: 10px;
    border-top-left-radius: 10px;
    border: 1px solid var(--background-gray);
    font-size: 20px;
    background-color: var(--secondary-color);
    color: #fff;
    height: 40px;
    width: 100%;
    text-align: center;
    display: flex;
    justify-content: center;
    align-items: center;

}

.team_person h3{    
    margin-top: 16px;
}

.team_section{
    display: flex;
    position: relative;
    flex-wrap: wrap;
    justify-content: center;
    align-items: center;
    gap: 16px;
}

@media (max-width:970px) {
    .team_person{
        width: calc(100% - 16px);
    }

    .team_section{
        gap: 32px;
    }
}
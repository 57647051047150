.service__item{
    border: 1px solid rgb(1, 1,1, 0.1);
    border-radius: 16px;
    height: auto;
    box-shadow: 1px 4px 1px 0 var(--background-gray);
    width: 314px;
    height: 430px;

    &:hover{
        cursor: pointer;
        transform: scale(1.02);
    }
}

.service__item_icon{
    width: 50px;
    height: 50px;
    margin: auto auto;
    display: flex;
    margin-top: 24px;
}

.service__item_title{
    font-weight: bold;
    text-align: center;
    margin: 16px 0;
    display: flex;
    justify-content: center;
    align-content: center;
    height: 40px;
    font-size: 20px;
    padding: 0 8px;
    color: var(--primary-color);
}
 
.service__description{
    width: 80%;
    margin: auto auto;
}

@media (max-width:768px) {
    .service__item_title{
        font-size: 20px;
    }
}
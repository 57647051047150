.tittle_tag {
    padding: 16px;
    border-radius: 25px;
    text-align: center;
    color: var(--text-color-white);
    background-color: var(--primary-color);
    width: 300px;
    margin: 32px auto 8px auto;
    font-size: 32px;
}

.tittle_section {
    position: relative;
    width: 250px;
    margin: auto auto;
    color: var(--background);

}

.tittle_text_primary {
    
    text-align: center;
    font-size: 22px;
    margin-bottom: 8px;
    font-weight: bold;
    position: relative;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-wrap: wrap;
}

.tittle_text_primary::after {
    content: '';
    position: absolute;
    bottom: -8px;
    left: 42%;
    transform: translateX(-50%);
    width: 12%; 
    height: 4px; 
    background-color: var(--tittle-primary); 
    
}



.contact_button{
    display: flex;
    background-color: #4BCA59;
    border-color: #4BCA59;
    color: #fff;
    margin: 24px 8px;
    border-radius: 8px;
    padding: 8px;
    text-align: center;
    font-size: 28px;
    width: 400px;
    margin: 40px auto;
    height: 80px;
    font-weight: bold;
    align-items: center;
    justify-content: center;
    gap: 16px;
    width: calc(1000px - 64px);

    &:hover{
        transform: scale(1.02);
        opacity: 0.7;
    }
}

.social_logo{
    width: 50px;
    height: 50px;
}

@media (max-width:970px) {
    .contact_button{
        width: calc(100% - 16px);

    }
}
.error{
    border: 1px solid red;
    border-radius: 2px;
}
.error_text{
    color: red;
}
.contato_section{
    width: 100%;
    background: var(--background);
    padding-top: 32px;
    padding-bottom: 64px;
}

.contato_ext{
    position: relative;     
    display: flex;
    justify-content: center;
    align-items: center; 
    flex-wrap: wrap;
    margin: auto auto;
    gap: 64px;
    
}

#contato{
    display: flex;
    justify-content: center;
    padding: 16px;
    position: relative;
    display: flex;
    justify-content: center;
    align-items: center;
}

.send-mail_form{
    position: relative;
    display: flex;
    max-width:450px;
    min-height:480px;   
    flex-wrap: wrap;
    box-shadow: 1px 2px 3px 4px rgb(1,1,1,0.1);
    color: #000;
    gap: 16px;
    background-color: var(--background-white);
    padding: 32px;
    border-top: 10px solid #B5967C;
    border-radius: 10px;
}
 
.box_form{
    position: relative;
    margin-top: 32px;
    text-align: center;
    display: flex;
    justify-content: center;
    align-items: center;
}

.send-mail_form__tittle_div{
    margin: auto auto;
    border-bottom: 4px solid var(--tittle-primary);
}

.mail_form__tittle{
    font-weight: bold;    
    font-size: 24px;
    color: var(--background);
}

.contato_mail_phone{
    text-align: left;
    display: flex;
    flex-wrap: wrap;
    gap: 32px;
    width: 200px;
}
 
.contato_social{
    margin-left: -16px;
    margin-top: 16px;
}

.contact_phone, .contact_mail{
    width: 100%;
    color: var(--text-color-white);
    text-align: left;
    display: flex;
    justify-content: left;
    gap: 10px;

}

@media (max-width:746px) {
    .contact_phone, .contact_mail{
        text-align: center;
        justify-content: center;
    
    }
    .contato_mail_phone{
        text-align: center;
        justify-content: center;
        
    }

    .contato_social{
        display: flex;
        justify-content: center;
    }
}
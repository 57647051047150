.lista_services{
    list-style-type: square;  
    margin: 0 auto;
    padding: 0 24px;
    width: 100%;
}

.financiamento_e_credito{
    padding-left: 16px;
    text-decoration: dashed;
    list-style-type: circle;
    margin: 0 auto;
    width: 100%;
}


#solucoes{
    position: relative;
     margin-bottom: 32px;
     justify-content: center;
     flex-wrap: wrap;
     align-items: center;
     width: 100%;
}
.services_type_tittle{
    font-size: 24px;
    font-weight: bold;
    text-align: center;
    width: 100%;
    color: var(--primary-color);
    margin: 24px 0;
}

.services_type_subtittle{
    text-align: center;
    max-width: 768px;
    margin: 24px auto;
}

.services_container{
    padding: 8px ;
    align-items: center;
    justify-content: center;
    flex-wrap: wrap;
    display: flex;
    max-width: 1500px;
    margin: 0 auto;
    gap: 24px;
    height: auto;
}
.services_description{
    text-align: justify;
    max-width: 1024px;
    margin: 48px auto;
    font-size: 18px;
    border: 1px solid var( --secondary-color);
    padding: 16px;
    border-radius: 12px;
    color: var(--primary-color);
    box-shadow: 1px 4px 1px 0 var(--background-gray);
}

@media (max-width:768px) {
    .services_type_subtittle{
        margin: 24px ;
    }

    .services_description{
        margin: 24px ;
        font-size: 16px;
        text-align: center;
        padding: 8px;
    }
}